import React from "react"
import { Link } from "gatsby"

const Paginator = ({ context, path }) => {
  const { currentPage, numPages } = context
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `${path}` : `${path}/${(currentPage - 1).toString()}`
  const nextPage = `${path}/${(currentPage + 1).toString()}`

  const createPaginationObjects = (length, currentPage, increment = 2) =>
    Array.from({ length }, (_, i) => ({
      link: `${path}/${i + increment}/`,
      index: i + increment,
      current: currentPage === i + increment,
    }))

  let navItems = [
    {
      link: `${path}/`,
      index: 1,
      current: currentPage === 1,
    },
  ]
  if (numPages <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: `${path}/${i + 2}/`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ]
  } else {
    if (currentPage <= 3) {
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, currentPage),
        {
          separator: true,
          index: "starter-separator",
        },
        {
          link: `${path}/${numPages}/`,
          index: numPages,
          current: false,
        },
      ]
    } else if (currentPage > numPages - 3) {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: "finisher-separator",
        },
        ...createPaginationObjects(4, currentPage, numPages - 3),
      ]
    } else {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: "starter-separator",
        },
        ...createPaginationObjects(3, currentPage, currentPage - 1),
        {
          separator: true,
          index: "finisher-separator",
        },
        {
          link: `${path}/${numPages}/`,
          index: numPages,
          current: false,
        },
      ]
    }
  }

  console.log("navItems", navItems)

  return (
    <section className="uk-section uk-section-small">
      <div className="uk-container">
        <ul className="uk-pagination uk-flex-center" data-uk-margin>
          {!isFirst && (
            <li>
              <Link to={prevPage} rel="prev">
                <span data-uk-pagination-previous="ratio: 1.25" />
              </Link>
            </li>
          )}
          {navItems.map(item => (
            <li key={item.index}>
              {item.separator ? (
                <span className="pagination-ellipsis">&hellip;</span>
              ) : (
                <Link
                  to={item.link}
                  aria-label={`Goto page ${item.index}`}
                  className={item.current ? "active" : ""}
                >
                  {item.index}
                </Link>
              )}
            </li>
          ))}
          {!isLast && (
            <li>
              <Link to={nextPage} rel="prev">
                <span data-uk-pagination-next="ratio: 1.25" />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}

export default Paginator
