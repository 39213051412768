import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import NewsList from "../components/News/NewsList"
import Paginator from "../components/Paginator"
import SEO, { seoPropsMapping } from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const News = ({ data, ...props }) => {
  const posts = data.allPrismicNewsEntry.edges
  const postNodes = posts.sort(
    (a, b) =>
      new Date(b.node.data.publish_date).getTime() - new Date(a.node.data.publish_date).getTime(),
  )

  const seoProps = seoPropsMapping(data.prismicVindiciaInTheNews.data)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header title={"Vindicia in the News"} />
      <section className="uk-section">
        <div className="uk-container uk-container-medium">
          <h2>Vindicia is making news</h2>
          <NewsList posts={postNodes} />
        </div>
      </section>
      <Paginator context={props.pageContext} path={"/company/vindicia-in-the-news"} />
    </Layout>
  )
}

export default withPreview(News)

export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allPrismicNewsEntry(
      sort: { fields: data___publish_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            publish_date
            short_title
            tile_image_for_news_list__required_ {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            resource_link {
              link_type
              target
              url
            }
          }
        }
      }
    }
    prismicVindiciaInTheNews {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
      }
    }
  }
`
