import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import { Image } from "../VindiciaComponents"

const NewsList = ({ posts }) => {
  return (
    <div
      className="uk-grid-column-medium uk-child-width-1-2@s news-list"
      data-uk-grid
      data-uk-height-match="target: > div > .uk-card > div .uk-card-body"
    >
      {posts.map((post, i) => (
        <div key={post.node.uid}>
          <div className="uk-inine uk-position-relative">
            <Image
              src={post.node.data.tile_image_for_news_list__required_.fixed.src}
              srcSet={post.node.data.tile_image_for_news_list__required_.fixed.srcSet}
              alt={post.node.data.short_title}
            />
            <div className="uk-overlay uk-overlay-primary uk-position-bottom uk-text-left">
              <p className="uk-h4">{post.node.data.short_title}</p>
              <p className="news-link">
                {moment(post.node.data.publish_date).format("MMM D, YYYY")} |{" "}
                <Link to={`/company/in-the-news/${post.node.uid}`}>
                  Read more <span uk-icon="icon: arrow-right"></span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default NewsList
