import React from "react"
import { Image } from "../VindiciaComponents"
import classnames from "classnames"

const Header = ({
  image,
  title,
  subtitle,
  text_alignment = "left",
  hero_button_label = null,
  hero_button_link = null,
}) => {
  const heroClass = classnames({
    "uk-flex-between": text_alignment === "right" || text_alignment === "left",
    "uk-margin-large-left": text_alignment === "right",
    "uk-flex uk-width-2-5@m uk-container uk-flex-auto uk-position-relative uk-flex-wrap uk-flex-middle": true,
  })

  const heroTextClass = classnames({
    "margin-bottom": text_alignment === "left",
    "margin-top": text_alignment === "right",
  })

  const heroImageClass = classnames({
    "uk-flex-first@m": text_alignment === "right",
  })

  return (
    <header>
      <div className="uk-container">
        <div className="uk-flex uk-flex-wrap uk-flex-middle">
          <div className={`uk-dark hero-copy uk-container uk-flex-auto uk-position-relative`}>
            <div className="uk-container">
              <div className="uk-flex uk-flex-wrap uk-flex-middle" style={{ gap: "15px" }}>
                <div className={heroClass}>
                  <div
                    className={heroTextClass}
                    data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
                  >
                    <h1 className="uk-margin-large-top">{title}</h1>
                    {subtitle ? <h4 className="uk-margin-remove-top">{subtitle}</h4> : null}
                    {hero_button_link ? (
                      <a
                        href={hero_button_link}
                        className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-right uk-margin-small-bottom"
                      >
                        {hero_button_label}
                      </a>
                    ) : null}
                  </div>
                </div>
                <div className={`uk-width-2-5@m ${heroImageClass}`}>
                  {image?.fluid && <Image src={image.fluid.src} srcSet={image.fluid.srcSet} />}
                  {image?.length && <Image src={image} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
